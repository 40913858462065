import React, { useState, useEffect, useRef } from "react";
import { Share, X } from "lucide-react";

const ShareButton = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [status, setStatus] = useState(null);
  const [message, setMessage] = useState("");
  const [consent, setConsent] = useState(false);
  const inputRef = useRef(null);

  const updateMessageFromURL = () => {
    const url = window.location.href;
    const parts = url.split("/");

    if (parts.includes("care-pack")) {
      setMessage(
        `Here is a link to your Care Pack: ${url}\n\nReply STOP to opt out of future messages.`
      );
      return;
    }

    if (parts.includes("caretaker")) {
      const doctorNameIndex = parts.indexOf("caretaker") + 1;
      if (doctorNameIndex < parts.length) {
        const doctorName = parts[doctorNameIndex]
          .replace(/-/g, " ")
          .replace(/(^\w|\s\w)/g, (m) => m.toUpperCase());

        if (doctorNameIndex + 1 < parts.length) {
          const service = parts[doctorNameIndex + 1]
            .replace(/-/g, " ")
            .replace(/(^\w|\s\w)/g, (m) => m.toUpperCase());
          setMessage(
            `Here is a link to information about ${service} from ${doctorName}: ${url}\n\nReply STOP to opt out of future messages.`
          );
        } else {
          setMessage(
            `Here is a link to ${doctorName}'s profile: ${url}\n\nReply STOP to opt out of future messages.`
          );
        }
      }
      return;
    }

    setMessage(
      `Here is a link to MyMedSky: ${url}\n\nReply STOP to opt out of future messages.`
    );
  };

  // Call when modal opens
  const handleModalOpen = () => {
    updateMessageFromURL();
    setIsModalOpen(true);
  };

  useEffect(() => {
    if (isModalOpen && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isModalOpen]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!consent) return;
    setStatus("sending");

    try {
      const response = await fetch(
        "https://us-central1-medsky-1cefe.cloudfunctions.net/sendSMSMessage",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            to: phoneNumber,
            body: message,
            consentObtained: true,
            consentTimestamp: new Date().toISOString(),
            consentMethod: "web form checkbox",
          }),
        }
      );

      const result = await response.json();

      if (result.success) {
        setStatus("success");
        setPhoneNumber("");
        setConsent(false);
        setTimeout(() => {
          setIsModalOpen(false);
          setStatus(null);
        }, 2000);
      } else {
        console.error("Function returned success: false", result);
        setStatus("error");
      }
    } catch (error) {
      console.error("Error calling sendSMSMessage:", error);
      setStatus("error");
    }
  };

  return (
    <>
      <button
        onClick={handleModalOpen}
        className="p-2 text-white hover:scale-105 transition-scale duration-200"
        aria-label="Share"
      >
        <Share size={20} />
      </button>

      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded-lg max-w-md w-full m-4">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-bold text-gray-800">
                Share Profile via Text Message
              </h2>
              <button
                onClick={() => setIsModalOpen(false)}
                className="text-gray-500 hover:text-gray-700 transition-colors duration-200"
                aria-label="Close"
              >
                <X size={24} />
              </button>
            </div>
            <p className="mb-4 text-sm text-gray-600">
              Enter your phone number to share this page.
            </p>
            <form onSubmit={handleSubmit} className="flex flex-col gap-3">
              <input
                type="tel"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                placeholder="Enter phone number"
                required
                className="p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none"
                ref={inputRef}
              />
              <div className="flex items-center gap-2">
                <input
                  type="checkbox"
                  id="consent"
                  checked={consent}
                  onChange={(e) => setConsent(e.target.checked)}
                  className="form-checkbox h-4 w-4 text-blue-600"
                />
                <label htmlFor="consent" className="text-xs text-gray-700">
                  I agree to receive one informational message from MedSky
                  containing my healthcare provider's information. I understand
                  that message and data rates may apply. Reply STOP to opt out.
                </label>
              </div>
              <button
                type="submit"
                disabled={status === "sending" || !consent}
                className={`py-2 px-4 rounded-md text-white font-medium transition-colors duration-200 ${
                  status === "sending" || !consent
                    ? "bg-blue-300 cursor-not-allowed"
                    : "bg-blue-600 hover:bg-blue-700"
                }`}
              >
                {status === "sending" ? "Sending..." : "Send"}
              </button>
              <p className="text-xs text-gray-500 mt-2">
                Message and data rates may apply. Reply STOP to opt out.
              </p>
              {status === "success" && (
                <p className="text-sm text-green-600 mt-2">
                  Message sent successfully!
                </p>
              )}
              {status === "error" && (
                <p className="text-sm text-red-600 mt-2">
                  Failed to send message. Please try again.
                </p>
              )}
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default ShareButton;
