import React from "react";
import { Link } from "react-router-dom";
import MedskyLogo from "../icons/medsky-logo.svg";
import ShareButton from "./ShareButton";

const Navbar = () => {
  return (
    <nav className="flex justify-between items-center p-4 bg-accent">
      <div className="w-1/3"></div>
      <div className="w-1/3 flex justify-center items-center">
        <Link to="/">
          <img
            src={MedskyLogo}
            alt="Medsky Logo"
            className="h-12 w-auto cursor-pointer"
          />
        </Link>
      </div>
      <div className="w-1/3 flex justify-end">
        <ShareButton />
      </div>
    </nav>
  );
};

export default Navbar;
